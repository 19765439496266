import { Component, OnInit, Input } from '@angular/core';
import { Part } from 'src/app/models/part';

@Component({
  selector: 'app-part-detail',
  templateUrl: './part-detail.component.html',
  styleUrls: ['./part-detail.component.css', '../part.component.css']
})
export class PartDetailComponent implements OnInit {
  @Input() part: Part;

  constructor() {}

  ngOnInit() {
  }

}
