import { Material } from '../models/material';

export const MATERIALS: Material[] = [
    {'_id': '5bef87bd17d0b91aa05c293a',
        'name': '6061 Aluminum',
        'active': true,
        'description': '6061 Aluminum',
        'type': 'Aluminum',
        'price_per_lb': 1,
        'density': 0.098},
    {'_id': '5b49f4e575c0b56fbe627176',
        'name': '1010 Carbon Steel',
        'active': true,
        'description': '1010 Carbon Steel',
        'type': 'Steel',
        'price_per_lb': 1.75,
        'density': 0.284},
    {'_id': '5b49f4e575c0b56fbe62716a',
        'name': 'A356 Aluminum',
        'active': true,
        'description': 'A356 Aluminum',
        'type': 'Aluminum',
        'price_per_lb': 1,
        'density': 0.098},
];
