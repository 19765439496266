import { Operation } from './operation';
import { Material } from './material';
import { PartWork } from './part-work';
import { MACHINES } from '../mocks/mock-machines';
import { Machine } from './machine';

export class Part {
    id: number;
    name: string;
    material: Material;
    stock?: any;
    x: number;
    y: number;
    z: number;
    volume: number;
    area: number;
    operations?: PartWork[];
    machines?: any[];

    constructor(part: any) {
        Object.assign(this, part);
        return this;
    }

    public addOperation(newOp: Operation): void {
        console.log('Adding op');
        const newId = this.operations ? this.operations.length : 0;
        const newWork: PartWork = {
            _id: newId,
            machine: MACHINES[0],
            op: newOp,
            qty: 1,
            price: 0
        };

        if (this.operations) {
            this.operations.push(newWork);
        } else {
            this.operations = [newWork];
        }
    }

    public deleteOperation(index: number): void {
        this.operations.splice(index, 1);
    }
    
    public changeOperationMachine(opIndex: number, machineIndex: number) {

    }

    public addMachine(newMachine: Machine): void {
        if (!newMachine._id) {
            newMachine._id = this.operations ? this.operations.length : 0;
        }

        if (this.machines) {
            this.machines.push(newMachine);
        } else {
            this.machines = [newMachine];
        }
    }

    public deleteMachine(index: number): void {
        this.machines.splice(index, 1);
    }

}
