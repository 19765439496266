import { Component, OnInit, Input } from '@angular/core';
import { OPERATIONS } from '../mocks/mock-operations';
import { Part } from '../models/part';
import { Operation } from '../models/operation';

@Component({
  selector: 'app-work-operations',
  templateUrl: './work-operations.component.html',
  styleUrls: ['./work-operations.component.css']
})
export class WorkOperationsComponent implements OnInit {
  @Input() part: Part;
  operations = OPERATIONS;

  constructor() { }

  ngOnInit() {
  }

  addOperation(op: Operation): void {
    this.part.addOperation(op);
  }

}
