import { Component, OnInit, Input } from '@angular/core';
import { Part } from 'src/app/models/part';

@Component({
  selector: 'app-part-viewer',
  templateUrl: './part-viewer.component.html',
  styleUrls: ['./part-viewer.component.css', '../part.component.css']
})
export class PartViewerComponent implements OnInit {
  @Input() part: Part;
  
  constructor() { }

  ngOnInit() {
  }

}
