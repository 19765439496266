import { Injectable } from '@angular/core';
import { Part } from '../models/part';
import { OPERATIONS } from '../mocks/mock-operations';
import { Operation } from '../models/operation';
import { Machine } from '../models/machine';
import { MACHINES } from '../mocks/mock-machines';
import { GroupByPipe } from '../directives/group-by.pipe';

@Injectable({
  providedIn: 'root'
})
export class PartService {
  parts: Part[];
  
  constructor(private groupBy: GroupByPipe) { }

  public trimMachines(part: Part): void {
    const machines = this.groupBy.transform(part.operations, 'machine.name');
    const newMachines = [];

    for (let i = 0; i < machines.length; i++) {
      newMachines.push(machines[i].value[0].machine);
    }

    part.machines = newMachines;
  }

  public sortOperationsByMachine(part: Part) {
    const machines = this.groupBy.transform(part.operations, 'machine.name');
    const machinesList = [];

    for (let i = 0; i < machines.length; i++) {
      machinesList.push({
        machine: machines[i].value[0].machine,
        operations: machines[i].value
      });
    }
    console.log(machinesList);
    return machinesList;
  }

  getAvailableOperations(): Operation[] {
    return OPERATIONS;
  }

  getAvailableMachines(): Machine[] {
    return MACHINES;
  }
}
