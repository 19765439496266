import { Component, Inject } from '@angular/core';
import { PartWork } from '../models/part-work';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-work-details',
  templateUrl: './work-details.component.html',
  styleUrls: ['./work-details.component.css']
})
export class WorkDetailsComponent {

  constructor(public dialogRef: MatDialogRef<WorkDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public work: PartWork) { }
    
  onNoClick(): void {
    this.dialogRef.close();
  }

}
