import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Part } from 'src/app/models/part';
import { Operation } from 'src/app/models/operation';
import { PartWork } from 'src/app/models/part-work';
import { WorkDetailsComponent } from 'src/app/work-details/work-details.component';
import { MatDialog } from '@angular/material';
import { PartService } from 'src/app/services/part.service';
import { MACHINES } from 'src/app/mocks/mock-machines';

@Component({
  selector: 'app-part-operations',
  templateUrl: './part-operations.component.html',
  styleUrls: ['./part-operations.component.css', '../part.component.css']
})
export class PartOperationsComponent implements OnInit {
  @Input() part: Part;
  machinesList: any[];

  constructor(public dialog: MatDialog, private partService: PartService) { }

  ngOnInit() {
    this.machinesList = this.partService.sortOperationsByMachine(this.part);
  }

  onSelect(op: Operation): void {
  }

  addMachine(): void {
    this.machinesList.push({machine: MACHINES[0], operations: []});
  }

  deleteOperation(id: number): void {
    this.part.deleteOperation(id);
  }

  openOperationDetails(work: PartWork) {
    const dialogRef = this.dialog.open(WorkDetailsComponent, { data: work });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log(event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

}
