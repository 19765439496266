import {Operation} from '../models/operation';

export const OPERATIONS: Operation[] = [
    {id: 100, name: 'Engineering', type: 'General'},
    {id: 101, name: 'Purchase Item', type: 'General'},
    {id: 1, name: 'Drill', type: 'CNC Turn'},
    {id: 2, name: 'Turn', type: 'CNC Turn'},
    {id: 3, name: 'Drill', type: 'CNC Mill'},
    {id: 4, name: 'Rough Pocket', type: 'CNC Mill'},
    {id: 5, name: 'Finish Pocket', type: 'CNC Mill'},
    {id: 6, name: 'Drill', type: 'CNC Turn-Mill'},
    {id: 7, name: 'Turn', type: 'CNC Turn-Mill'},
    {id: 8, name: 'Rough Pocket', type: 'CNC Turn-Mill'},
    {id: 9, name: 'Finish Pocket', type: 'CNC Turn-Mill'},
];
