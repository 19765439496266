import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {

  transform(collection: Array<any>, property: string): Array<any> {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return null;
    }

    function deep(col: Array<any>, name: string): any {
      const props = name.split('.');
      for (const p of props) {
        if (col[p] === undefined) {
          return undefined;
        }
        col = col[p];
      }

      return col;
    }

    const groupedCollection = collection.reduce((previous, current) => {

      const key = current[property]; // deep(current, property);

      if (!previous[key]) {
        previous[key] = [current];
      } else {
        previous[key].push(current);
      }

      return previous;
    }, {});

    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
  }
}
