import { Component, OnInit } from '@angular/core';
import { Part } from '../models/part';
import { OPERATIONS } from '../mocks/mock-operations';
import { MATERIALS } from '../mocks/mock-materials';
import { STOCKS } from '../mocks/mock-stocks';

@Component({
  selector: 'app-part',
  templateUrl: './part.component.html',
  styleUrls: ['./part.component.css']
})
export class PartComponent implements OnInit {
  operations = OPERATIONS;
  selectedPart: Part = new Part({
    id: 1,
    name: 'Drop Forward',
    material: MATERIALS[0],
    stock: STOCKS[0],
    x: 4.396,
    y: 2.5,
    z: 0.73,
    volume: 1.788,
    area: 17.717,
    operations: [],
  });

  constructor() { }

  ngOnInit() {
    this.selectedPart.addOperation(this.operations[0]);
    this.selectedPart.addOperation(this.operations[2]);
  }

}
