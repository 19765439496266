import { Operation } from './operation';
import { Machine } from './machine';

export class PartWork {
    _id: number;
    machine: Machine;
    op: Operation;
    qty: number;
    description?: string;
    details?: any;
    price?: number;
}
