import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PartComponent } from './part/part.component';
import { PartDetailComponent } from './part/part-detail/part-detail.component';
import { PartOperationsComponent } from './part/part-operations/part-operations.component';
import { PartMaterialComponent } from './part/part-material/part-material.component';
import { WorkOperationsComponent } from './work-operations/work-operations.component';
import { GroupByPipe } from './directives/group-by.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatExpansionModule,
  MatSelectModule,
  MatIconModule,
} from '@angular/material';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { PartViewerComponent } from './part/part-viewer/part-viewer.component';
import { WorkDetailsComponent } from './work-details/work-details.component';

@NgModule({
  declarations: [
    AppComponent,
    PartComponent,
    PartDetailComponent,
    PartOperationsComponent,
    PartMaterialComponent,
    WorkOperationsComponent,
    GroupByPipe,
    PartViewerComponent,
    WorkDetailsComponent,
  ],
  imports: [
    BrowserModule,
    DragDropModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatExpansionModule,
    MatSelectModule,
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
  ],
  entryComponents: [
    WorkDetailsComponent
  ],
  providers: [GroupByPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
