import { Component, OnInit, Input } from '@angular/core';
import { Part } from '../../models/part';
import { MATERIALS } from '../../mocks/mock-materials';
import { STOCKS } from '../../mocks/mock-stocks';

@Component({
  selector: 'app-part-material',
  templateUrl: './part-material.component.html',
  styleUrls: ['./part-material.component.css', '../part.component.css']
})
export class PartMaterialComponent implements OnInit {
  @Input() part: Part;
  materials = MATERIALS;
  stocks = STOCKS;

  constructor() { }

  ngOnInit() {
  }

}
